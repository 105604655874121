import React from 'react';
import styled from 'styled-components';
import useScriptDutchie from '../../hooks/useScriptDutchie';
import logo from '../../assets/images/logo-1.png'

const LiveMenu = () => {
  const FullScreen = styled.div`
    background-color: #f8f7f2;
  width: 100%;

  .title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 70px 30px;
    img{
      max-width: 800px;
    }
    h1{
      color: var(--darkgreen);
      font-size: 30px;
    };
  }
`;

  return (
    <>
    <FullScreen>
        <div className="title">
          <img src={logo} />
        </div>  
        <div id="dutchie--embed__script" />
        {useScriptDutchie(`https://dutchie.com/api/v2/embedded-menu/thehouseofcannabis.js?chainLocations=true`)}
    </FullScreen>
    </>
  );
};

export default LiveMenu;
